<header mdcTopAppBar="fixed" class="blox-header" dense [fixedAdjust]="main">
  <a class="blox-material-header-logo" routerLink="/" tabindex="-1"></a>
  <div mdcTopAppBarRow class="blox-header-icon-actions">
    <div mdcTopAppBarSection alignStart class="blox-page-title">Blox Material v{{version}}</div>
    <div mdcTopAppBarSection alignEnd><blox-theme-switcher></blox-theme-switcher></div>
  </div>
  <div mdcTopAppBarRow class="blox-container">
    <div mdcTopAppBarSection alignStart></div>
    <div mdcTopAppBarSection alignEnd>
      <nav mdcTabBar>
        <div mdcTabScroller>
          <div mdcTabScrollerArea>
            <div mdcTabScrollerContent>
              <a mdcTab [routerLink]="['guides']">
                <span mdcTabContent>
                  <span mdcTabLabel>Guides</span>
                </span>
                <span mdcTabIndicator>
                  <span mdcTabIndicatorContent></span>
                </span>
              </a>
              <a mdcTab [routerLink]="['components']">
                <span mdcTabContent>
                  <span mdcTabLabel>Components</span>
                </span>
                <span mdcTabIndicator>
                  <span mdcTabIndicatorContent></span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</header>
<main #main class="blox-main">
  <div class="blox-container">
    <router-outlet></router-outlet>
  </div>
</main>
<footer class="blox-footer">
  <div class="blox-container">
    <section>
      <a mdcButton href="https://twitter.com/intent/follow?screen_name=TheSourceZone" class="blox-nobreak blox-social" target="_blank"
        angulartics2On="click" angularticsCategory="social" angularticsAction="click" angularticsLabel="twitter">
        <span mdcButtonIcon class="blox-inline-svg" [inlineSVG]="svgTwitter" [setSVGAttributes]="svgAttributes" [resolveSVGUrl]="false"></span>
        <span mdcButtonLabel>Follow Blox Material</span>
      </a>
      <a mdcButton href="https://github.com/src-zone/material" class="blox-nobreak blox-social" target="_blank"
        angulartics2On="click" angularticsCategory="social" angularticsAction="click" angularticsLabel="github">
        <span mdcButtonIcon class="blox-inline-svg" [inlineSVG]="svgGithub" [setSVGAttributes]="svgAttributes" [resolveSVGUrl]="false"></span>
        <span mdcButtonLabel><span class="weak">github.com/</span>src&#8209;zone/material</span>
      </a>
    </section>
    Powered by The Source Zone &copy; 2017 - {{year}}, Amsterdam<br/>
    Code licensed under Apache License v2.0<br/>
    Documentation licensed under CC BY 4.0
  </div>
</footer>
