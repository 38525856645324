import { Component } from '@angular/core';

@Component({
  selector: 'blox-docs',
  templateUrl: './docs.component.html'
})
export class DocsComponent {
    constructor() {
    }
}
