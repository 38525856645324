<div mdcMenuAnchor>
  <button mdcIconButton class="material-icons"
    title="Change theme"
    [disabled]="!themes.length"
    [mdcMenuTrigger]="changeTheme">format_color_fill</button>
  <div mdcMenu #changeTheme="mdcMenu" (pick)="setTheme($event.value)">
    <ul mdcList>
      <li *ngFor="let key of themes" mdcListItem [value]="key">
        <div mdcRadio>
          <input mdcRadioInput type="radio" name="theme" [value]="key" [checked]="key === theme"/>
        </div>
        <span mdcListItemText>{{getName(key)}}</span>&nbsp;&nbsp;
        <i mdcListItemMeta [innerHTML]="svg" class="blox-theme-thumb-svg theme-thumb-{{key}}"></i>
      </li>
    </ul>
  </div>
</div>
