<div class="blox-img-list">
    <div class="blox-index-intro">
        <h1>A lightweight Material Design library for Angular</h1>
        <p>
            Open-source and based on Google's Material Components for the Web
        </p>
        <section>
            <a mdcButton [routerLink]="['guides','gettingstarted']" class="blox-button-attract">Get Started</a>
        </section>
    </div>
    <div class="blox-img-list-row">
        <div class="blox-img-list-img blox-bg-fast"></div>
        <div class="blox-img-list-cap">
            <h2>Fast and small</h2>
            <p>
                Lightweight components that are performant on all devices from mobile to desktop.
                Optimized for effective tree shaking: Functionality not used gets thrown out
                in production builds. Smaller bundles means faster website!
            </p>
        </div>
    </div>
    <div class="blox-img-list-row">
        <div class="blox-img-list-img blox-bg-themeable"></div>
        <div class="blox-img-list-cap">
            <h2>Themeable</h2>
            <p>
                Easy adjustment of the look and feel of your website.
                Customize colors, sizes, animations, and layout with Sass or Css.
                Includes theme mixins that help maintain accessibility and design.
            </p>
        </div>
    </div>
    <div class="blox-img-list-row">
        <div class="blox-img-list-img blox-bg-accessible"></div>
        <div class="blox-img-list-cap">
            <h2>Responsive and Accessible</h2>
            <p>
                Modern UI components that are designed with responsiveness, and accessibility in mind.
                Because all users are welcome.
            </p>
        </div>
    </div>
    <div class="blox-img-list-row">
        <div class="blox-img-list-img blox-bg-agnostic"></div>
        <div class="blox-img-list-cap">
            <h2>Framework Agnostic</h2>
            <p>
                Experimenting with different front-end frameworks? No need to restyle every time
                you or your teams switch framework! Stylesheets for Blox Material work for any other
                Material Components Web integration library. There are integrations for Vue, React,
                Web Elements, and many others.
            </p>
        </div>
    </div>
    <div class="blox-index-foot">
        <section>
            <a mdcButton [routerLink]="['guides','gettingstarted']" class="blox-button-attract">Get Started</a>
        </section>
    </div>
</div>
