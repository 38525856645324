import { Component } from '@angular/core';

@Component({
  selector: 'blox-guides',
  templateUrl: './guides.component.html'
})
export class GuidesComponent {
    static DOC_TYPE = 'docs';
    static DOC_HREF = 'guides';
}
