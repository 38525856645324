<div class="blox-code-sample-toolbar">
  <div class="blox-code-sample-toolbar-firstrow">
    <h3><ng-content select="[bloxSampleTitle]"></ng-content></h3>
    <div class="blox-code-sample-toolbar-actions">
      <button *ngIf="hasSource" mdcIconButton (click)="stackblitz()" class="material-icons"
        [title]="openStackblitz ? 'Edit in Stackblitz IDE' : (disableStackblitz ? 'Stackblitz not supported for this sample' : 'Preparing Stackblitz IDE')"
        [disabled]="!openStackblitz"
        >{{stackblitzIcon}}</button>
      <button *ngIf="hasSource" mdcIconToggle
        [(on)]="showCode"
        labelOn="Hide source code" labelOff="Show source code"
        title="{{showCode ? 'Hide source code' : 'Show source code'}}">
        <i mdcIcon="on" class="material-icons">expand_less</i>
        <i mdcIcon class="material-icons">expand_more</i>
      </button>
    </div>
  </div>
  <nav *ngIf="showCode" mdcTabBar class="blox-sample-tab-bar">
    <div mdcTabScroller>
      <div mdcTabScrollerArea>
        <div mdcTabScrollerContent>
          <a *ngFor="let name of snippetNames" mdcTab (activate)="activate(name)" [active]="isActive(name)" tabindex="0" role="link">
            <span mdcTabContent><span mdcTabLabel>{{name}}</span></span>
            <span mdcTabIndicator><span mdcTabIndicatorContent></span></span>
          </a>
        </div>
      </div>
    </div>
  </nav>

</div>
<pre *ngIf="showCode && active"><code highlightJs [source]="snippet.code[active]" [lang]="language(active)"></code></pre>
<ng-content select="[bloxSample]"></ng-content>
